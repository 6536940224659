<template>
  <div>
    <Banner>
      <img style="width:502px;height:456px;bottom:41px" src="@/assets/image/productCenter/mobileShield-banner-bg.png" alt="手机盾">
      <template v-slot:text>
        <div class="title">手机盾</div>
        <div class="hint-multi">手机盾是面向移动端场景的一款产品,提供可信的数字签名功能。协同签名系统采用密钥分割技术和协同数字签名技术,实现了密钥分量在移动签名组件和服务器端的独立生成和独立存储。签名过程中,移动端和服务端协作,共同完成完整签名。可适用于OA协同、电子政务、移动办公、金融支付、健康医疗、移动端身份认证等多种场景。</div>
        <el-button @click="$bus.$emit('cgContactFormShow', true)" type="primary">产品咨询</el-button>
      </template>
    </Banner>
    <Functional class="functional" :list="functional" />
    <!-- <Features :list="features" /> -->
    <LearnMore />
  </div>
</template>

<script>
// import Features from './components/Features.vue'
import Functional from './components/Functional.vue'
import LearnMore from './components/LearnMore.vue'
export default {
  components: { Functional, LearnMore },
  data () {
    return {
      functional: [
        {
          icon: 'functional-icon4.png',
          title: '身份认证',
          intro:
            '手机盾服务提供基于PKI证书和电子签名的身份认证方式，认证强度远高于口令和动态密码等认证手段，有效防止用户身份被伪造。'
        },
        {
          icon: 'functional-icon8.png',
          title: '证书与密钥管理',
          intro:
            '手机盾服务提供密钥的生成、安全存储、更新、销毁，以及证书的申请、签发、更新、吊销等管理功能，对用户在移动终端上的核心数据进行安全保护，确保用户密钥数据安全。'
        },
        {
          icon: 'functional-icon16.png',
          title: '密码算法支持',
          intro:
            '手机盾服务全面支持国产算法以及部分国际通用密码算法，满足用户各种加密的算法需求。'
        },
        {
          icon: 'functional-icon17.png',
          title: '低成本',
          intro:
            '手机盾服务不依赖任何额外的硬件密码模块，相比传统U盾等硬件设备成本更低，用户体验更好。'
        }
      ],
      features: [
        {
          title: '免部署',
          intro: '免部署即享可信时间戳云服务，安全可信，节约成本。'
        },
        {
          title: '权威国家时间源',
          intro:
            '豸信CA建立的可信时间戳云服务平台依托国家授时中心的可信时间源，合法合规，有法律保障。'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep.functional {
  .item {
    width: 286px;
    height: 410px;
    padding: 32px 42px;
  }
}
</style>